import React, { Component } from 'react'
import { CenterWrapper, Text, View, TechnoQuestBanner, SectionWrapper } from 'lib'
import { withStyles } from '@material-ui/styles'
import { Settings } from '_appSetup'

class DevelopmentPolicy extends Component {
  render() {
    const styles = this.props.classes
    return (
      <View style={styles.wrapper}>
        <CenterWrapper>
          <Text variant={'h4'}>Early development privacy policy</Text>
          <Text style={styles.subText}>The mobile app you running is currently in early development.</Text>
          <Text style={styles.subText}>All data is available to developers except sensitive information such as passwords, which are securely encrypted.</Text>
          <Text style={styles.subText}>The application collects and stores information provided by the user (you).</Text>
          <Text style={styles.subText}>The application may also collect usage information for analytics and bug reporting.</Text>
          <Text style={styles.subText}>By using the app you agree to participate in our development programme and to share your data with the developers and app management team.</Text>
          <Text style={styles.subText}>For any further information, please contact <a href={`mailto:${Settings.SUPPORT_EMAIL}`} className={styles.link}>{Settings.SUPPORT_EMAIL}</a>.</Text>
          <SectionWrapper>
            <a href={'/'}>
              <TechnoQuestBanner/>
            </a>
          </SectionWrapper>
        </CenterWrapper>
      </View>
    )
  }
}


const styles = theme => ({
  wrapper: {
    flex: 1,
    display: 'flex',
    minHeight: '100vh',
    alignItems: 'center',
  },
  subText: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(3),
  },
  link: {
    color: 'inherit',
  },
  linkText: {
    fontSize: 20,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
})

export default withStyles(styles)(DevelopmentPolicy)
